.ant-popover.ant-popover-placement-bottomRight {
  // adjust notification Popover position
  .ant-popover-inner {
    position: relative;
    right: -90px;
  }
  > .ant-popover-content {
    > .ant-popover-arrow {
      margin-right: 3px;
      width: 16px;
      height: 16px;
      border-width: 8px;
      z-index: 2;
    }
  }
}

.ant-layout {
  .app-header {
    background-color: #fff;
    padding: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 2px solid #f6f6f6;

    .header-right {
      padding-right: 6px;
      display: flex;
      align-items: baseline;
      > div {
        line-height: 20px;
        height: 20px;
        padding: 0 12px;
        display: inline-block;
      }
    }

    // icon to open/close left sider
    .trigger {
      font-size: 18px;
      line-height: 64px;
      padding: 0 20px;
      cursor: pointer;
      transition: color 0.3s;
    }

    .trigger:hover {
      color: #1890ff;
    }
  }
}
.badge-for-count {
  display: inline-block;
  position: relative;
  left: -6px;
  top: -8px;
  padding: 0 6px;
  min-width: 16px;
  height: 16px;
  line-height: 16px;
  color: #fff;
  font-size: 10px;
  white-space: nowrap;
  text-align: center;
  background: #ff4d4f;
  border-radius: 10px;
  box-shadow: 0 0 0 1px #fff;
}
