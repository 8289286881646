@import "~antd/dist/antd.css";

$layout-padding: 20px;
$layout-margin: 20px;

@font-face {
  font-family: "PF";
  font-weight: 400;
  src: url("./images/font/PingFangRegular.woff");
}
@font-face {
  font-family: "PF";
  font-weight: 500;
  src: url("./images/font/PingFangBold.woff");
}

body {
  font: 14px PF, sans-serif;
}

// layout content blocks inside <Content></Content>
.c0 {
  //content0
  margin: 0;
  padding: $layout-padding;
  background-color: white;
  h2 {
    font-size: 1.4em;
    line-height: 2;
    margin: 0.6em 0 0.8em;
  }
}
.ant-layout.ant-layout-has-sider {
  min-height: 100vh;
}

.c1 {
  // content1
  margin: $layout-margin;
  padding: $layout-padding;
  background-color: white;
}

/*--margin spaces using bootstrap convention--*/
.pb-0 {
  padding-bottom: 0;
}
.px-0 {
  padding-left: 0;
  padding-right: 0;
}
.pb-3 {
  padding-bottom: 1rem;
}
.mx-3 {
  margin-left: 1rem;
  margin-right: 1rem;
}
.my-0 {
  margin-top: 0;
  margin-bottom: 0;
}
.my-2 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.my-5 {
  margin-top: 3rem;
  margin-bottom: 3rem;
}
.ml-16px {
  // used for table title where its column has a link button that has {padding: 4px 15px} and 1px border
  margin-left: 16px;
}

.cursor-pointer {
  cursor: pointer;
}

// override antd's default rgba(0, 0, 0, 0.25)
input[type="text"]:disabled {
  color: rgba(0, 0, 0, 0.5);
}
.ant-breadcrumb {
  font-weight: 500;
}

// custom classes
.min-height-50p {
  min-height: 50vh;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.center-x {
  // applied to container. Its content is horizontally center aligned
  display: flex;
  justify-content: center;
}
.center-y {
  // applied to container. Its content is vertically center aligned
  display: flex;
  align-items: center;
}

// ------------------- custom ant components --------------------
.ant-btn {
  &.tab-style {
    margin-right: 32px;
    border-width: 0 0 2px 0;
    display: inline-flex;
    padding: 0;
    height: 45px;
    align-items: center;
    &.ant-btn-link {
      border-bottom-color: #1890ff;
      font-weight: 500;
    }
    &.ant-btn-text {
      border-bottom-color: transparent;
      font-weight: normal;
    }
    span {
      display: inline-block;
    }
  }
}
.ant-drawer-header {
  border-bottom: 1px solid #666;
}
.ant-drawer-title {
  display: flex;
  align-items: center;
  font-size: 1.2em;
  font-weight: 500;
}
.ant-drawer-body {
  padding: 32px;
}
.ant-drawer-footer {
  border-top: 1px solid #666;
}

//<Result status="warning"> in ant drawer is used for showing validation errors that shouldn't occupy large spaces
.ant-drawer-body .ant-result-warning {
  position: relative;
  padding: 6px 0 20px;
  .ant-result-icon {
    margin-bottom: 10px;
    position: absolute;
    left: 20px;
  }
  .ant-result-title,
  .ant-result-subtitle {
    text-align: left;
    padding-left: 120px;
  }
}
