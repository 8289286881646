$logoHeight: 22px;

.brand {
  height: 64px; // better to be consistent with the antd header's height
  overflow: hidden;
  padding-left: 26px;
  display: flex;
  justify-content: left;
  align-items: center;
  &:first-child {
    margin-right: 12px;
  }
  .logo1,
  .logo2 {
    width: auto;
    height: $logoHeight;
  }
  .logo2 {
    margin-left: 12px;
    opacity: 1;
    transition: opacity 0.5s;
  }
}

.ant-layout-sider-collapsed {
  .logo2 {
    opacity: 0;
  }
}

.ant-layout-sider-children
  .ant-menu-root.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal)
  .ant-menu-item-selected {
  background-color: transparent;
  font-weight: bold;
}
