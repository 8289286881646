.register {
  background: #f0f2f5;
  min-height: 100vh;
  &__header {
    padding: 20px 20px 0;
    text-align: center;
  }
  &__logo {
    height: 50px;
  }
  &__content {
    // text-align: center;
    width: 420px;
    margin: 0 auto;
    padding-top: 40px;

    &__send {
      // margin-left: -20px;
      padding-top: 15px;
    }
  }

  &__steps {
    width: 720px;
    margin-left: auto;
    margin-right: auto;
  }
}

.code-count-text {
  color: #25b864;
}

.finished {
  text-align: center;
  margin: 0 auto;
  h2 {
    margin-top: 20px;
    margin-left: 18px;
  }
}

.login_form {
  width: 350px;
  padding-left: 40px !important;
}

.login_switch {
  text-align: left;
  color: #8c8c8c;
  cursor: pointer;
}
